(function() {
  'use strict';
  angular
    .module('app')
    // .constant('BASE_URL', 'http://localhost:4000')
    // .constant('DOMAIN_URL', 'http://localhost:3000')
    .constant('BASE_URL', 'https://operadora.testcda.com')
    .constant('DOMAIN_URL', 'https://operadora.testcda.com')
    .constant('_', _)
    .constant('moment', moment)
    // .constant('accounting', accounting)
    // .constant('swal', swal);
})();
